<template>
  <div class="home">
    <child-page-head :title="title" />
    <el-row :gutter="8" type="flex" justify="center">
      <el-col :span="8">
        <basic-card title="检测点">
          <div>dsada</div>
        </basic-card>
      </el-col>
      <el-col :span="16">
        <basic-card title="车辆实时数据">
          <div>dsada</div>
        </basic-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import childPageHead from '@/components/child-page-head'
import basicCard from '@/components/basic-card.vue'
export default {
  name: 'Vehicle',
  components: {
    childPageHead,
    basicCard
  },
  data() {
    return {
      title: '车辆监测'
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100vw);
  height: calc(100vh);
  background: url("../../assets/image/body-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
