<template>
  <dv-border-box-7
    :color="['#10568e', 'rgb(124, 231, 253)']"
    class="basic-card-box"
  >
    <div>
      <div class="basic-card-body-view">
        <div v-if="hasVal(title)" class="child-page-title">
          <h3>{{ title }}</h3>
        </div>
        <div class="basic-card-context">
          <slot />
        </div>
      </div>
    </div>
  </dv-border-box-7>
</template>

<script>
import { hasVal } from '@/utils/index'
export default {
  name: 'BasicCard',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    hasVal: hasVal
  }
}
</script>

<style lang="scss" scoped>
.basic-card-box {
  width: 100%;
  height: 242px;
  border: 0px;
  background: rgba(30, 32, 43, 0.04);
  opacity: 0.8;
  .basic-card-body-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .basic-card-context {
      margin-top: 20px;
    }
  }
}
</style>
